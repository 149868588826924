import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const SpeedcamEn = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Speedcam Brasil"
        description="website for  Speedcam Brasil"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <h1>Speedcam Brasil</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <a
              className="btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 px-3 py-1.5"
              href="https://speedcambrasil.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              speedcambrasil.com.br
            </a>
            <div className="h-5" />
            <p>
              A Speedcam Brasil é uma locadora especializada em câmeras de alta
              velocidade e lentes especiais para produção de cinema comercial.
            </p>
            <p>
              O objetivo do website é mostrar as câmeras e lentes atualmente
              disponíveis e vídeos feitos com essas técnicas também.
            </p>
          </div>
        </div>
        <div className="h-8" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desenvolvimento</div>
          <div className="col-span-4 tec-feature">gastby js</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantação</div>
          <div className="col-span-4 tec-feature">aws S3 e cloudfront</div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel images={data.portfolioImages.edges} alt="Speedcam Brasil" />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default SpeedcamEn

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/speedcam/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
